
.interviewList .Title {
    font-weight: normal;
    margin-left: 10px;
}

.interviewList .SubTitle {
    margin-left: 10px;
}

// Hide the icon-backdrop spacer when we're not hovering on the item
.interviewList .ListItem .interview-list-actionbar-backdrop {
    display: none;
}

.interviewList .ListItem:hover .interview-list-actionbar-backdrop {
    display: inline-block;
}

.transcriptIndicator {
    display: block;
    position: absolute;
    font-size: 18px;
    width: 100px !important;
    height: 18px;
    bottom: 5px !important;
    right: 0px;
}

.transcriptIndicator .Icon {
 	float: right;
}

.interviewListInsightCount {
  float: right;
  padding-top: 0px;
  padding: 3px;
  padding-bottom: 0px;
  padding-right: 4px;
  padding-left: 4px;
  margin-top: -1px;
  font-size: 16px;
  line-height: 17px;
  background-color: #F7F7F8;
  color: white;
  border-radius: 360px;
  margin-right: 2px;
}

.interviewTable {
    overflow: overlay;
}

.interviewTable::-webkit-scrollbar {
    background: transparent;
    width: 12px;
}

.interviewTable::-webkit-scrollbar-thumb, .interviewTable::-webkit-scrollbar-thumb:window-inactive {
    border: 4px solid rgb(255 255 255 / 5%);
}

.arrowup {
    transition: transform 1s ease-in-out;
}

.arrowdown {
    transform: rotate(-180deg);
}
