@import '../../../../scss/variables.module.scss';

.remove-tag-icon {
  display: flex;
  align-items: center;
  .MuiSvgIcon-root:hover {
    color: #C62828;
    transform: scale($icon-scale-factor);
  }
}

.unclicked {
  flex-direction: row;
  transition: flex-direction 1s ease-out;
  -moz-transition: flex-direction 1s ease-out;   
  -webkit-transition: flex-direction 1s ease-out; 
  -o-transition: flex-direction 1s ease-out;
}

.clicked {
  flex-direction: row-reverse;
    transition: flex-direction 1s ease-out;
  -moz-transition: flex-direction 1s ease-out;   
  -webkit-transition: flex-direction 1s ease-out; 
  -o-transition: flex-direction 1s ease-out;
}

.submenu-item {
  background-color: #FFFFFF;
}

.submenu-item:hover {
  background-color: #DDECFF;
}