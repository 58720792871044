/** styles - body */
$body-font-size: 15px;

/** styles - primary */
$primary-background: #F7F7F7;
$primary-border-color: rgba(0,0,0,0.1);
$primary-font-color: #0d0d0d;
$primary-icon-color: #0d0d0d;
$primary-font-size: .8rem;

/** styles - primary dark */
$primary-background-dark: #272B2B;
$primary-font-color-dark: #fafafa;

/** styles - secondary */
$secondary-background: #e9e9e9;
$secondary-border-color: rgba(0,0,0,0.1);;
$secondary-font-size: 1.1rem;

/** styles - third */
$third-background: #e3e3e3;
$third-border-color: rgba(0,0,0,0.1);;
$third-font-size: 1.0rem;

/** styles - fourth */
$fourth-background: #dfdfdf;
$fourth-border-color: $third-border-color;

/** misc */
$selection-background: #d2d2d2;

$header-background: #505050;
$header-font-color: #e3e3e3;
$header-icon-color: #e3e3e3;

$notification-color: #e74c3c;
$notification-font-color: #d9d9d9;

/** accents */
$primary-accent: rgb(82,176,203);

$default_border_radius: 2px;

$blockquote-background-color: #f4f4f4;
/** mixins */
@mixin flex($row_or_col: row, $align_items: stretch) {
    display: flex;
    flex-flow:$row_or_col;
    align-items: $align_items;
}

@mixin border_radius($border_radius: $default_border_radius)  {
    -webkit-border-radius: $border_radius;
    -moz-border-radius: $border_radius;
    border-radius: $border_radius;
}

@mixin button($background: $fourth-background, $font_color: $primary-font-color) {
    padding:  8px 10px;
    background: $background;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: $font_color;
    @include border_radius();
}
