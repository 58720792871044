.Audio {
    .SubTitle {
        margin: 0;
        margin-left: 16px;
        margin-right: 16px;

        line-height: 56px;
        vertical-align: top;

        display: inline-block;
    }
}