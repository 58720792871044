// Animations
@mixin play-style {
    color: white;
    background-color: black;
}

@keyframes paused-word {
    0% { @include play-style; }

    50% {
        color: initial;
        background-color: lightgrey;
    }

    100% { @include play-style; }
}

.quill {
    // Ensure that quill is dispalyed in a block
    // format to prevent it from shifting our
    // teacup layout component in smaller resolutions
    display: block !important;
    // Make sure it scrolls when the window gets too short
    min-height: calc(100vh - 123px);
}

.ql-editor {
    min-height: calc(100vh - 223px);
}

.ql-container {
    border: 0px !important;
}

// In-editor styles
.ql-snow { // all modifications are for quill's snow theme
    &.ql-container {
        font: inherit;
        border: '0px';

        user-select: text; // when user has no edit privs, this allows them
                           // to still select text to make an insight.

        // For bold and italic, respective. These for some reason
        // have a different sized top, bottom padding and that makes highlights
        // look distorted from their neighbors.
        & strong, & em {
            padding-top: 2px;
            padding-bottom: 1px;
        }
    }

    &.ql-toolbar {
        text-align: center;
    }

    // we change the default values for headings to help our customers
    // with confusion around skipping values since we don't offer all 6
    // available headers
    .ql-picker-item, .ql-picker-label {
        &[data-value="2"]::before {
            content: 'Heading 2' !important;
        }

        &[data-value="4"]::before {
            content: 'Heading 1' !important;
        }
    }

    // tooltips show up under some of our stuff, but they should
    // always be on top.
    .ql-tooltip {
        z-index: 99999;
    }
}

/* Word Stylings */

// Selected Words
.current-selection-artifact-editor-internal {
    background-color: lightgrey;
}

// Low Confidence
.low-confidence-word-artifact-editor-internal {
    color: red;
}

// Currently Playing Word(s)
.playing .currently-playing-word-artifact-editor-internal {
    color: white;
    background-color: black !important; // overwrite inline finding background
}

.paused .currently-playing-word-artifact-editor-internal {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(0, end);
    animation-name: paused-word;
}

// User modified words
.transcript-word-changes-visible .fixed-up-word-artifact-editor-internal {
    color: white;
    background-color: #0F9B57;
}

// Make all low confidence formatted words red
[low-confidence-word-format-id="true"] {
    color: red;
}

// Transcript help modal
.transcript-help-modal {
    // container: invisible, clickable div
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0);
    z-index: 999999999;

    $help-key-height: 41px;
    $help-key-right-spacing: 12px;

    section {
        background: white;
        color: black;
        margin-left: auto;
        margin-right: auto;
        z-index: 15;
        border: 1px solid #cccccc;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding: 20px;
        width: 50%;
        min-width: 850px;
    }

    // This is wholesale copy pasta'd from a github
    .key{
        display: block;
        color: #828282;
        font: bold 9pt arial;
        text-decoration: none;
        text-align: center;
        width: 44px;
        height: $help-key-height;
        line-height: $help-key-height;
        background: #eff0f2;
        -moz-border-radius: 4px;
        border-radius: 4px;
        border-top: 1px solid #f5f5f5;
        text-shadow: 0px 1px 0px #f5f5f5;
        float: left;
        margin-right: $help-key-right-spacing;
    }

    table{
        width: 90%;
        margin: auto;
    }

    .key-special {
        display: block;
        font-size: 11pt;
        text-transform: lowercase;
        width: 70px;
    }

    .plus {
        float: left;
        color: #828282;
        line-height: $help-key-height;
        width: 15px;
        height: $help-key-height;
        margin-right: $help-key-right-spacing;
    }

    td:first-of-type {
        width: 50%;
        text-align: right;
        padding-right: 30px;
    }

    td {
        padding-bottom: 10px;
    }

    h2 {
        text-align: center;
        padding-bottom: 15px;
    }

    div { // additional help link
        text-align: right;
        padding-right: 30px;
    }
}

/* Finding highlighting */

// For the colors here, please refer to Rebecca's excellent color chart:
// https://docs.google.com/spreadsheets/d/1Ci0sv5sIhuqFg42OBkZ_QyUp9NcWJyOUrjHFXPLNeDc/edit#gid=0

// this allows us to turn off findings for the transcript editor
.findings-visible {

    // General
    [data-finding-type-name="General Insight"] {
        background-color: #FFF9B3;
        font-style: italic;
    }

    // New Feature
    [data-finding-type-name="New Feature"] {
        background-color: #C6F8B4;
        font-style: italic;
    }

    // New Question
    [data-finding-type-name="New Question"] {
        background-color: #CBE3FF;
        font-style: italic;
    }

    // Interview Candidate
    [data-finding-type-name="Interview Candidate"] {
        background-color: #EED2FF;
        font-style: italic;
    }

    // New Hypothesis
    [data-finding-type-name="New Hypothesis"] {
        background-color: #FDB9CB;
        font-style: italic;
    }

    // Feedback
    [data-finding-type-name="Feedback"] {
        background-color: #FFCF9C;
        font-style: italic;
    }
}
